<template>
  <action-dispatcher action="courses/getAll">
    <div class="view-search py-5 container-fluid">
      <h5 class="mb-4">Buscando por: {{ $route.query.s }}</h5>

      <div class="row">
        <div
          v-for="(course, i) in filteredCourses"
          :key="i"
          class="col-lg-3 col-md-4 col-sm-6 mb-4 d-flex"
        >
          <course-card class="flex-fill" :course="course" />
        </div>
      </div>

      <em
        v-if="filteredCourses.length === 0"
        class="text-secondary d-block small mb-5 pb-5"
        >Nenhum resultado</em
      >
    </div>
  </action-dispatcher>
</template>

<script>
import CourseCard from "@/components/home/CourseCard";
import { mapState } from "vuex";

export default {
  components: { CourseCard },
  computed: {
    ...mapState("courses", ["courses"]),
    filteredCourses() {
      return this.courses.filter(
        c =>
          c.title.toLowerCase().indexOf(this.$route.query.s.toLowerCase()) >= 0
      );
    }
  }
};
</script>
